@font-face {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  src: local('Space Grotesk Regular'), url(./fonts/SpaceGrotesk-Regular.ttf) format('truetype');
}

body {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1rem;
}

button {
  padding: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  background-color: black;
  color: white;
  border-radius: 8px;
  cursor: pointer;
}

h1 { 
  text-align: center;
  font-size: 2rem !important;
}

h3, h4 {
  margin-bottom: 0;
}

nav {
  border-bottom: 4px solid #24BDBF;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav li {
  display: inline-block;
  padding-top: 24px;
  padding-bottom: 24px;
}

nav a {
  text-decoration: none;
  color: black;
  font-weight: bold;
  padding: 24px 32px;
}

nav a:hover {
  color: #24BDBF;
}

.ql-editor h1 {
  text-align: center;
  font-size: 1.125rem !important;
  font-weight: 400;
  color: #14436B !important;
}

.ql-editor h2 {
  font-size: 1rem !important;
  color: #0FB3D3 !important;
}

.ql-editor a {
  text-decoration: none;  /* Remove default underline */
  color: #d53f8c !important;
}

.ql-editor a:hover {
  text-decoration: underline;  /* Underline on hover */
  color: #d53f8c !important;
}

#page-body {
  max-width: 700px;
  margin: auto;
}

.article-list-item {
  text-decoration: none;
  color: black;
  padding: 16px 0 0 0;
}

.article-list-date {
  color: gray;
  font-size: 0.5 rem !important;
}

.experience-list-item-company {
  text-decoration: none;
  color: #14436B;
  padding: 16px 0 0 0;
}

.experience-list-item-title {
  text-decoration: none;
  color: #0FB3D3;
  padding: 12px 0 0 0;
}



.article-list-item p {
  padding-bottom: 32px;
  border-bottom: 2px solid black;
}

#add-comment-form {
  border-bottom: 2px solid black;
  padding: 16px;
}

#add-comment-form h3 {
  margin-bottom: 16px;
}

input, textarea {
  display: block;
  width: 300px;
  padding: 8px;
  border-radius: 8px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  border: 2px solid black;
  margin-bottom: 16px;
}

.error {
  padding: 8px;
  border-radius: 4px;
  background-color: pink;
}

#add-comment-form button {
  width: 320px;
}

.comment {
  border-bottom: 2px solid black;
  padding: 16px;
}

.nav-right {
  position: absolute;
  top: 8px;
  right: 8px;
}

.logo {
  height: 8rem;
  width: 8em;
  margin-left: auto;
  margin-right: auto;
}
.avatar {
  height: 17rem;
  width: 17rem;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.small-text {
  font-size: 0.75em;
  color: black;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.sub-text {
  font-size: 0.75em;
  color: grey;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.dates-subtext {
  font-size: 0.75em;
  color: black;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 0;
}

/* Custom Drawer Styles */
.drawer-content {
  background-color: #14436B !important;
}

.drawer-header,
.drawer-body a,
.drawer-body button {
  color: #0FB3D3 !important;
}

.hamburger-button {
  background-color: #14436B !important;
  color: #0FB3D3 !important;
}

.hamburger-button:hover {
  background-color: #0FB3D3 !important;
  color: #14436B !important;
}